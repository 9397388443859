<template>
    <div class="global-content">
        <van-collapse v-model="activeNames" accordion>
            <van-collapse-item title="筛选条件" name="1">
                <van-cell title="按设备编号" :value="filter.devName" is-link @click="devPicker=true"/>
                <van-cell title="按媒体类型" :value="filter.type == 2? '照片' : '视频'" is-link @click="typeShow=true"/>
                <van-button type="info" class="btn-filter" @click="filterMedia">筛选</van-button>
            </van-collapse-item>
        </van-collapse>
        <van-popup v-model="devPicker" round position="bottom">
            <van-search v-model="filterKeyword" placeholder="请输入搜索关键词" @input="filterDev" />
            <van-picker show-toolbar :columns="devData" @cancel="devCancel" @confirm="devConfirm"/>
        </van-popup>
        <van-action-sheet v-model="typeShow" :actions="typeActions" @select="typeSelect" />
        <div class="inner">
            <div v-if="!emptyData">
                <div v-if="dataList.length != 0">
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false">
                        <div class="block global-shadow" v-for="(item,index) in dataList" :key="index">
                            <div v-if="filter.typeConfirm == 2" @click="previewImage(item.fileUrl)">
                                <van-row>
                                    <van-col span="6">
                                        <div class="img"><img :src="item.fileUrl"/></div>
                                    </van-col>
                                    <van-col span="15">
                                        <div class="title">{{item.fileName}}</div>
                                        <div class="time">设备编号：{{item.fileDevCode}}</div>
                                        <div class="time">{{item.fileUpTime}}</div>
                                    </van-col>
                                    <van-col span="3">
                                        <van-icon name="eye-o" />
                                    </van-col>
                                </van-row>
                            </div>
                            <div v-else @click="playVideo(item.fileUrl)">
                                <van-row>
                                    <van-col span="6">
                                        <div class="img"><img src="@/assets/itgs/media-thumb.jpg"/></div>
                                    </van-col>
                                    <van-col span="15">
                                        <div class="title">{{item.fileName}}</div>
                                        <div class="time">设备编号：{{item.fileDevCode}}</div>
                                        <div class="time">{{item.fileUpTime}}</div>
                                    </van-col>
                                    <van-col span="3">
                                        <van-icon name="play-circle-o" />
                                    </van-col>
                                </van-row>
                            </div>
                        </div>
                    </van-list>
                </div>
                <div v-else><van-loading class="z-loading" size="24px" vertical>加载中...</van-loading></div>
            </div>
            <div v-else><van-empty description="没有更多数据了" /></div>
        </div>
        <van-popup v-model="videoBox">
            <div class="video-box">
                <video ref="video" width="360" height="240" :src="videoUrl" controls></video>
            </div>
        </van-popup>
        <tabBar/>
    </div>
</template>

<script>
import tabBar from '@/components/itgs/tabBar.vue';
import wx from 'weixin-js-sdk';

export default {
  name: 'Media',
  components: {
    tabBar
  },
  data(){
      return{
        activeNames:'',
        filterKeyword:'',
        emptyData:false,
        devPicker:false,
        typeShow:false,
        filter:{
            devName:"",
            devCode:'',
            type:2, //默认是图片，图片为2，视频为1
            typeConfirm:2, //默认是图片，图片为2，视频为1
        },
        dataList:[],
        devData:[],
        devDataNon:[],
        typeActions:[{name:'照片',id:2},{name:'视频',id:1}],
        loading:false,
        finished:false,
        pageNum:1,
        pageSize:10,
        videoBox:false,
        videoUrl:'',
        visitor:1
      }
  },
  mounted(){
    // 获取所有设备信息
    const projectCode = window.sessionStorage.getItem('projectCode');
    this.$api.ITGS.getAllDevInfo({
        projectCode:projectCode
    }).then((data)=>{
        data.forEach(element => {
            element.text = element.devName;
        });
        this.devData = data;
        this.devDataNon = data;
    })
    this.onLoad();
  },
  methods:{
      previewImage(thumb){
          if(this.visitor=='1'){
            this.$toast('当前为游客模式，暂无权限')
            return false;
          }
          wx.previewImage({
            current: thumb,
            urls: [thumb]  
          });
      },
      // 筛选单个设备
      devConfirm(e){
          this.filter.devName = e.text;
          this.filter.devCode = e.devCode;
          this.devPicker = false;
      },
      // 筛选单个设备 ---- 取消
      devCancel(){
          this.devPicker = false;
          this.filter.devName = '';
          this.filter.devCode = '';
          this.filterKeyword = '';
      },
      // 筛选媒体类型
      typeSelect(e){
          this.filter.type = e.id;
          this.typeShow = false;
      },
      // 筛选单个设备
      filterDev(k){
          this.devData = this.devDataNon.filter( e => e.text.toLowerCase().includes(k.toLowerCase()))
      },
      // 向服务端发送筛选请求
      filterMedia(){
          this.filter.typeConfirm = this.filter.type;
          this.activeNames= '';
          this.dataList = [];
          this.pageNum = 1;
          this.finished=false;
          this.onLoad();
      },
      // 播放视频
      playVideo(url){
        if(this.visitor=='1'){
            this.$toast('当前为游客模式，暂无权限')
            return false;
        }
        this.$dialog.confirm({
            title: '提示',
            message: '播放视频将消耗流量，点击确定继续播放',
        })
        .then(() => {
            this.videoUrl = url;
            this.videoBox = true;
        })
        .catch(() => {});
      },
      // 分页加载媒体文件
      onLoad(){
            this.visitor = window.localStorage.getItem('visitor');
            const projectCode = window.sessionStorage.getItem('projectCode');
            const postData = {
                fileDevCode:this.filter.devCode,
                fileType:this.filter.typeConfirm,
                projectCode:projectCode
            } 
            this.$api.ITGS.getMediaFile(this.pageNum,this.pageSize,postData).then((data)=>{
                this.dataList = this.dataList.concat(data);
                this.dataList.length == 0 ? this.emptyData = true : this.emptyData = false;
                data.length < this.pageSize ? this.finished = true : void 0
                this.loading = false;
                this.pageNum ++;
            })
      }
  }
}
</script>

<style lang="scss" scoped>
.van-empty{margin: 25% 0;}
.btn-filter{width: 25%;height: 2rem;margin: 4% auto;display: block;}
.inner{
    border-radius: 10px;
    margin-bottom: 10%;
    .block{
        padding: 2%;
        margin-top: 6px;
        background-color: #fff;
        border-radius: 10px;
        .title{font-size: .9rem;font-weight:bold;line-height: 2;color: #525252;width: 100%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
        .time{font-size: .75rem;color: #616161;opacity: 0.6;line-height: 1.5;}
        .img img{
            width: 80px;
            height: 70px;
            border-radius: 5px;
        }
        .van-icon{font-size: 1.6rem;line-height: 2.5;color: #0957ff;}
    }
}
</style>